  <template>
    <v-row no-gutters>
      <v-col>
        <div id="payment-link-list">
          <!-- app drawer -->
  
          <payment-link-add-update
            v-model="isAddUpdatePaymentLinkSidebarActive"
            :payment-link-type="paymentLinkType"
            :payment-link-item="paymentLinkItem"
            @refetch-data="fetchPaymentLinks"
            @upload-image="uploadImage"
            @save-payment-link-event="savePaymentLinkEvent"
          ></payment-link-add-update>
  
     <!--      <payment-link-filter v-model="isAddUpdatePaymentLinkSidebarActive" @filter-data="filterData"> </payment-link-filter> -->
  
          <Image-upload
            v-model="isUploadProductImageActive"
            :data="dataUpload"
            :isUploadImageModul="modul"
            :isImageSize="imageSize"
            @refetch-data="fetchPaymentLinks"
            @save-image-event="saveImageEvent"
          />
  
          <v-snackbars :objects.sync="snackbars" width="500" top right transition="slide-y-transition">
            <template v-slot:action="{ close }">
              <v-btn icon small @click="close()">
                <v-icon small>
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </template>
          </v-snackbars>
  
          <!-- list filters -->
  
          <v-card
            tile
            :flat="$vuetify.breakpoint.mdAndDown"
            :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'"
          >
            <v-toolbar
              v-if="!$vuetify.breakpoint.smAndDown"
              :color="$vuetify.breakpoint.mdAndDown ? 'transparent' : 'transparent'"
              flat
              :height="$vuetify.breakpoint.smAndDown ? 57 : 87"
            >
              <v-toolbar-title class="text-h6 font-weight-medium">
                <v-icon large left>
                  {{ icons.mdiCreditCardSettingsOutline }}
                </v-icon>
                Ödeme Linkleri
              </v-toolbar-title>
  
              <v-spacer></v-spacer>
            
  
           <!--    <v-btn plain text small @click.stop="isAddUpdatePaymentLinkSidebarActive = !isAddUpdatePaymentLinkSidebarActive">
                <v-badge v-if="isFilterNumber" overlap color="tertiary" dot offset-x="15" offset-y="5">
                  <v-icon left>
                    {{ icons.mdiFilter }}
                  </v-icon>
                </v-badge>
                <v-icon v-else left>
                  {{ icons.mdiFilter }}
                </v-icon>
                Filitre
              </v-btn>
   -->
              <v-btn
                class="text-capitalize ms-3 rounded-l-pill rounded-r-0 mr-n4 bg-gradient-primary white--text"
                depressed
                x-large
                @click="addPaymentLink()"
              >
                <span>Ödeme Linki Ekle</span>
              </v-btn>
            </v-toolbar>
            <v-expansion-panels v-else v-model="panel" flat tile>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="text-body-2 font-weight-medium">
                    <v-icon left>
                      {{ icons.mdiCreditCardSettingsOutline }}
                    </v-icon>
                    Kullanıcılar
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pa-0">
                  <v-divider></v-divider>
                  <v-list class="pa-0" color="grey">
                    <v-list-item @click="addPaymentLink()">
                      <v-list-item-content>
                        <v-list-item-title>Ürün Ekle</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon>{{ icons.mdiPlusThick }}</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click.stop="isAddUpdatePaymentLinkSidebarActive = !isAddUpdatePaymentLinkSidebarActive">
                      <v-list-item-content>
                        <v-list-item-title>Filitre</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon :color="isFilterNumber ? 'tertiary' : null">
                          {{ icons.mdiFilter }}
                        </v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
  
            <!-- table -->
            <v-data-table
              id="table"
              v-model="selectedRows"
              :headers="tableColumns"
              :items="paymentLinkListTable"
              :options.sync="options"
              :items-per-page="15"
              fixed-header
              :height="dataTableHeight"
              :server-items-length="totalPaymentLinkListTable"
              :loading="loading"
            >
              <!-- Text -->
  
              <template #[`item.dosya_adi`]="{ item }">
                <v-card
                  tile
                  class="rounded d-flex align-center justify-center"
                  width="40"
                  height="40"
                  :color="item.pictures ? 'white' : 'primary'"
                >
                  <v-img
                    v-if="item.pictures"
                    height="30"
                    width="30"
                    :src="'https://portalapi.mesan.com.tr/' + item.dosya_adi"
                  ></v-img>
                  <v-icon v-else>
                    {{ icons.mdiCameraOff }}
                  </v-icon>
                </v-card>
              </template>
  
              <template #[`item.is_active`]="{ item }">
                <v-chip :color="item.is_active === '1' ? 'success' : 'warning'" link outlined small>
                  {{ item.is_active === '1' ? 'Aktif' : 'Pasif' }}
                </v-chip>
              </template>
  
             
              <template #[`item.id`]="{ item }">
                <div class="text-body-2 white--text">
                  <span class="white--text text-caption">{{ item.id }}</span>
                </div>
              </template>
              
              <template #[`item.create_datetime`]="{ item }">
                <div class="text-caption white--text">
                  {{ item.create_datetime | moment('Do MMMM YYYY HH:MM ') }}
                </div>
              </template>

              <template #[`item.payment_code`]="{ item }">  
            
               <div class="text-body-2 white--text">
                  <v-btn                 
                  v-if="item.payment_code"  
                  :disabled="item.is_active != 1"                
                  class="mr-2"
                  depressed
                  x-small
                  outlined                    
                >
                <v-icon small class="mr-2">
                  {{ icons.mdiCreditCardSettingsOutline }}
                </v-icon>
                <span class="mr-2"> <a :id="item.payment_code" target="_blank"  :href="'/payment-link-form/' +item.payment_code"> {{'https://portal.mesan.com.tr/payment-link-form/'+item.payment_code}}</a></span> 
          
              </v-btn>
          <!--     <v-btn depressed color="nightDark" small class="text-capitalize text-caption" @click="CopyLink(item)">
                Copy
              </v-btn> -->
              </div>   
      
              </template>
              

              <template #[`item.start_date`]="{ item }">
                <div class="text-caption white--text">
                  {{ item.start_date | moment('Do MMMM YYYY') }}
                </div>
              </template>
              <template #[`item.end_date`]="{ item }">
                <div class="text-caption white--text">
                  {{ item.end_date | moment('Do MMMM YYYY') }}
                </div>
              </template>  
      
  
              <template #[`item.actions`]="{ item }">               
  
                <v-tooltip bottom color="secondary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      color="nightDark"
                      :class="item.dosya_adi ? 'rounded-l-0 rounded-r-xl' : 'rounded-0'"
                      x-small
                      v-bind="attrs"
                      @click="editPaymentLink(item)"
                      v-on="on"
                    >
                      <v-icon small>
                        {{ icons.mdiSquareEditOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Düzenle</span>
                </v-tooltip>
  
                <v-tooltip v-if="item.dosya_adi" bottom color="warning">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      color="warning"
                      x-small
                      plain
                      class="rounded-l-0 rounded-r-xl"
                      v-bind="attrs"
                      @click="paymentLinkImageDelete(item)"
                      v-on="on"
                    >
                      <v-icon small>
                        {{ icons.mdiCameraOff }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Resimi Kaldır</span>
                </v-tooltip>
                <v-tooltip v-else bottom color="secondary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      color="nightDark"
                      x-small
                      class="rounded-l-0 rounded-r-xl"
                      v-bind="attrs"
                      @click="paymentLinkImageUpload(item)"
                      v-on="on"
                    >
                      <v-icon small>
                        {{ icons.mdiCamera }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Resim Yükle</span>
                </v-tooltip>
  
                <v-tooltip bottom color="error">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed color="error" x-small plain v-bind="attrs" @click="paymentLinkDelete(item)" v-on="on">
                      <v-icon small>
                        {{ icons.mdiDelete }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Ürünü Sil</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </template>
  
  <script>
  import router from '@/router'
  import ImageUpload from '@/components/ImageUpload.vue'
  import store from '@/store'
  import {
    mdiCamera,
    mdiCameraOff,
    mdiClose,
    mdiCreditCardSettingsOutline,
    mdiDelete,
    mdiEye,
    mdiFilter,
    mdiFilterOff,
    mdiPlusThick,
    mdiSquareEditOutline,
    
    mdiHelpCircleOutline,
  } from '@mdi/js'
  import { onUnmounted, ref, watch } from '@vue/composition-api'
  import VSnackbars from 'v-snackbars'
  import Vue from 'vue'
  //import paymentLinkStoreModule from '../paymentLinkStoreModule'
  import paymentLinkAddUpdate from './PaymentLinkAddUpdate.vue'
 // import productFilter from './views/products/payment-link-list/ProductFilter.vue'
  



  export default {
    
    components: {
      paymentLinkAddUpdate,
      //productFilter,
      ImageUpload,
      VSnackbars    },
    setup() {

      const PAYMENT_LINK_APP_STORE_MODULE_NAME = 'app-payment-link'
      const snackbars = ref([])
  
      const isAddUpdatePaymentLinkSidebarActive = ref(false)
      const isFilterNumber = ref(0)
      const isFilterProductSidebarActive = ref(false)
      const isUploadProductImageActive = ref(false)
      const dataUpload = ref({})
      const paymentLinkListTable = ref([])
      const paymentLinkType = ref('add')
      const paymentLinkItem = ref({})
  
      const tableSearch = ref('')
      const tableColumns = [
        {
          text: 'ID',
          value: 'id',
          width: '50px',
          sortable: false,
        },
        {
          text: 'Resim',
          value: 'dosya_adi',
          width: '90px',
          sortable: false,
        },
        {
          text: 'Başlık',
          value: 'stext',
          width: '350px',
          sortable: false,
        },        
        {
          text: 'Oluşturma Tarihi',
          value: 'create_datetime',
          width: '180px',
          sortable: false,
        },
        {
          text: 'Link',
          value: 'payment_code',
          width: '250px',
          sortable: false,
        },
        {
          text: 'Satış Açık',
          value: 'is_active',
          width: '90px',
          sortable: false,
        },
        {
          text: 'Baş.Tarihi',
          value: 'start_date',
          width: '150px',
          sortable: false,
        },  
        {
          text: 'Bit.Tarihi',
          value: 'end_date',
          width: '150px',
          sortable: false,
        },         
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
          width: '150',
        },
      ]
      const searchQuery = ref('')
      const urunKodFilter = ref(null)
      const idFilter = ref(null)
      const statusFilter = ref(null)
      const baslikFilter = ref(null)
      const customerFilter = ref(null)
      const totalPaymentLinkListTable = ref(0)
      const customerGroupOptions = ref([])
      const pricelistOptions = ref([])
      const loading = ref(false)
      const options = ref({
        sortBy: ['id'],
        sortDesc: [true],
      })
      const paymentLinkTotalLocal = ref([])
      const selectedRows = ref([])
  

      const routerPush = id => {
        
       router.push({ name: 'payment-link-form', query: {id: id } })
    
       // let route = router.resolve({ name:'payment-link-form' , query: { params:  { id }  } });
        //window.open(route.route.fullPath, "_blank");

        //let path= 'https://portal.mesan.com.tr/payment-link/payment-link-list/' + id 
        //window.open(path, "_blank");


    }
      const paymentLinkImageUpload = item => {
        
        dataUpload.value = item
        isUploadProductImageActive.value = true
      }
      const uploadImage = item => {
        dataUpload.value = item
        isUploadProductImageActive.value = true
      }

      
      const CopyLink = item => {
        debugger
        const area = "https://portal.mesan.com.tr/payment-link-form/'"+item.payment_code // document.querySelector("a[target]").CopyLink
        //const area = document.document.querySelector('#table')
        area.select();
        document.execCommand('copy')
      }
     // const statusFind = (arr, value, select) => store.state.tanimlar[arr].find(item => item.value === value)[select]
  
      const paymentLinkImageDelete = item => {        
        Vue.swal({
          title: 'Emin misin?',
          text: 'Resimi silme işlemini onaylayın',
          icon: 'question',
          background: '#FFB400',
          showCancelButton: true,
          showCloseButton: false,
          width: '300px',
          confirmButtonText: 'Onaylıyorum',
          cancelButtonColor: '#FFB400',
          confirmButtonColor: '#cc9000',
        }).then(result => {
          if (result.isConfirmed) {            
            loading.value = true
            store
              .dispatch('postMethod', {
                method: 'removeMediaItem',
                uploadid: item.pictures.bresim[0].uploadid,
              })
              .then(() => {
                snackbars.value.push({
                  message: `${item.baslik} Ürünün Resmi Silindi`,
                  color: 'error',
                  timeout: 3000,
                })
  
                fetchPaymentLinks()
              })
              .catch(e => {
                Vue.swal({
                  title: 'Hata',
                  text: e.message,
                  width: '350px',
                  icon: 'error',
                  background: '#FF4C51',
                  showCloseButton: false,
                  showConfirmButton: true,
                  confirmButtonColor: '#cc3d41',
                })
              })
          }
        })
      }
  
      const paymentLinkDelete = item => {
        
        Vue.swal({
          title: 'Emin misin?',
          text: 'Ödeme Linki silme işlemini onaylayın',
          icon: 'question',
          background: '#FF4C51',
          showCloseButton: false,
          showCancelButton: true,
          width: '300px',
          confirmButtonText: 'Onaylıyorum',
          cancelButtonColor: '#FF4C51',
          confirmButtonColor: '#cc3d41',
        }).then(result => {
          if (result.isConfirmed) {
            loading.value = true
            store
              .dispatch('postMethod', {
                method: 'deletePaymentLink',
                id: item.id,
              })
              .then(() => {
                snackbars.value.push({
                  message: `${item.stext} Ödeme Linki Silindi`,
                  color: 'error',
                  timeout: 5000,
                })
  
                fetchPaymentLinks()
              })
              .catch(e => {
                Vue.swal({
                  title: 'Hata',
                  text: e.message,
                  width: '350px',
                  icon: 'error',
                  background: '#FF4C51',
                  showCloseButton: false,
                  showConfirmButton: true,
                  confirmButtonColor: '#cc3d41',
                })
              })
          }
        })
      }
  
      const plants = val => {
        let result
  
        if (val) {
          result = val.split(',')
        } else {
          result = []
        }
  
        return result
      }
      const savePaymentLinkEvent = val => {
        snackbars.value.push(val)
      }
      const saveImageEvent = val => {
        snackbars.value.push(val)
      }
  
      const editPaymentLink = item => {        
        
        paymentLinkItem.value = item
        paymentLinkType.value = 'edit'
        isAddUpdatePaymentLinkSidebarActive.value = true
      }
      const addPaymentLink = () => {
        
        paymentLinkItem.value = {}
        paymentLinkType.value = 'add'
        isAddUpdatePaymentLinkSidebarActive.value = true
      }
      const filterData = item => {
        idFilter.value = item.id || null
        statusFilter.value = item.aktif || null
        urunKodFilter.value = item.urun_kodu || null
        baslikFilter.value = item.baslik || null
  
        let i = 0
        // eslint-disable-next-line no-restricted-syntax, no-unused-vars
        for (const [key, value] of Object.entries(item)) {
          if (value) {
            i += 1
          }
        }
        isFilterNumber.value = i
      }

      const odemeYap = item => {
        debugger
      console.log(item.payment_code)
      //router.push({ path: '/payment-link-form/' + item.payment_code })
      //router.push('/payment-link-form/' + item.payment_code )
      //this.$router.push({ name: "payment-link-form"})
    }
  
      const fetchPaymentLinks = () => {                
        const query = {
        options: options.value,
        't.id': idFilter.value,      
      }
        const method = 'getPaymentLinks'
        store
          .dispatch('fetchMethod', { query, method })
          .then(response => {      
                  
            paymentLinkListTable.value = response.response.result
            totalPaymentLinkListTable.value = Number(response.response.pagination.totalRec)
            loading.value = false
          })
          .catch(e => {
            loading.value = false
            Vue.swal({
              title: 'Hata',
              text: e.message,
              width: '350px',
              icon: 'error',
              background: '#FF4C51',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonColor: '#cc3d41',
            })
          })
      }
  
      watch([searchQuery, idFilter, urunKodFilter, statusFilter, baslikFilter, customerFilter, options], () => {
        loading.value = true
        selectedRows.value = []
        fetchPaymentLinks()
      })
  
      return {
        paymentLinkImageDelete,
        //statusFind,
        plants,
        paymentLinkItem,
        editPaymentLink,
        addPaymentLink,
        paymentLinkType,
        isFilterNumber,
        filterData,
        paymentLinkListTable,
        tableColumns,
        searchQuery,
        urunKodFilter,
        baslikFilter,
        idFilter,
        statusFilter,
        totalPaymentLinkListTable,
        loading,
        options,
        paymentLinkTotalLocal,
        isAddUpdatePaymentLinkSidebarActive,
        isFilterProductSidebarActive,
        isUploadProductImageActive,
        selectedRows,
        fetchPaymentLinks,
        paymentLinkDelete,
        snackbars,
        paymentLinkImageUpload,
        dataUpload,
        uploadImage,
        savePaymentLinkEvent,
        saveImageEvent,
        customerFilter,
        customerGroupOptions,
        pricelistOptions,
        tableSearch,
        odemeYap,
        routerPush,
        CopyLink,
        // icons
        icons: {
          mdiCamera,
          mdiDelete,
          mdiCameraOff,
          mdiCreditCardSettingsOutline,
          mdiEye,
          mdiFilter,
          mdiFilterOff,
          mdiPlusThick,
          mdiSquareEditOutline,
          mdiClose,
          
          mdiHelpCircleOutline,
        },
      }
    },
    data() {
      return {
        modul:"payment_links",
        imageSize :[
        {
          bresim:1500 ,
          kresim:150 
        } ],
        panel: null,
        date: null,
        modal: false,
        filterStatus: false,
        dataTableHeight: '',
        height: {
          system: 0,
          top: this.$vuetify.breakpoint.smAndDown ? 48 : 87,
          footer: this.$vuetify.breakpoint.smAndDown ? 47 : 47,        
        },
      }
    },
  
    watch: {
      panel(val) {
        
        if (val === 0) {
          setTimeout(() => {
            this.height.top = 250
            this.onResize()
          }, 300)
        } else {
          this.height.top = 48
          this.onResize()
        }
      },
    },
  
    mounted() {
      
      this.$nextTick(() => {
        this.onResize()
        window.addEventListener('resize', this.onResize)
      })
    },
  
    beforeDestroy() {
      
      window.removeEventListener('resize', this.onResize)
    },
    methods: {
      onResize() {
        
        this.dataTableHeight = window.innerHeight - 90 - this.height.top - this.height.footer - 1
        
      },

    
      copy() {
        debugger

        const area = document.querySelector('#clipboard-area')
        area.select();
        document.execCommand('copy')

   /*    this.$refs.myinput.focus();
      document.execCommand('copy'); */
    },
    copyURL() {
      debugger
     // Get the text field
      var copyText = document.getElementById("myInput");

      // Select the text field
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices

      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.value);
      document.execCommand('copy')
      // Alert the copied text
      alert("Copied the text: " + copyText.value);
    }

    },
  
  
  
  }
  </script>
  
  <style lang="scss">
  @import '@core/preset/preset/apps/user.scss';
  </style>
  